export default {
  name: 'apps',

  translatedAttributes: [
    'name',
    'short_description',
    'description',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    ...item,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...data,
    ...initialItemData,
  }),

  resolveValidationErrors: errors => ({
    ...errors,
  }),
}
