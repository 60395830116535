import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@/libs/axios'
import appModel from './appModel'

const {
  fetchOne,
  create,
  // update,
  deleteOne,
  resolveRoute,
} = useModelStoreModule(appModel, true)

const update = (ctx, itemData) => new Promise((resolve, reject) => {
  axios
    .put(resolveRoute('update', itemData.id), appModel.toArray(itemData))
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(response.data)
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOne,
    create,
    update,
    deleteOne,
  },
}
