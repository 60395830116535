<template>
  <b-card
    no-body
    class="tutorial-step"
  >
    <b-card-header class="pb-1">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <feather-icon
            v-if="success"
            size="40"
            icon="CheckIcon"
            variant="light-primary"
          />
          <b-avatar
            v-else
            size="40"
            :text="String(number)"
            variant="light-primary"
          />
        </b-media-aside>
        <b-media-body>
          <h5
            style="line-height: 40px"
            v-html="markdownToHtml"
          />
        </b-media-body>
      </b-media>
    </b-card-header>
    <b-card-body
      v-if="image"
    >
      <b-img
        :src="image"
        class="w-100 h-auto"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BCardBody, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { marked } from 'marked'

export default {
  components: {
    BImg,
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    number: {
      type: [String, Number],
      default: 1,
    },
    text: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    markdownToHtml() {
      return this.text ? this.marked(this.text) : this.text
    },
  },

  setup() {
    const renderer = marked.Renderer
    renderer.link = (href, title, text) => `<a href="${href}" target="_blank">${text}</a>`

    marked.use({ renderer })

    return {
      marked,
    }
  },
}
</script>

<style>
.tutorial-step .media-body p {
  line-height: 40px;
  margin-bottom: 0;
}
</style>
