<template>
  <b-card-actions
    :collapsed="isCollapsed"
    bg-variant="light-secondary"
    action-collapse
    :title="title"
    :sub-title="`${stepsCount} ${this.$t(`cards.stepsCounter.${stepsLabel}`)} | ${time}`"
  >
    <!-- Title -->
    <!--    <b-card-title class="mb-1 text-center font-weight-bolder">-->
    <!--      {{ title }}-->
    <!--    </b-card-title>-->
    <!--    <b-card-sub-title class="text-center mb-2 pb-0">-->
    <!--      <div class="text-center mb-2">-->
    <!--        <span class="flex flex-none flex-row">{{ steps.filter(step => !step.success).length }} {{ $t('Steps') }}-->
    <!--          <span class="px-2 text-slate-200"> | </span>-->
    <!--        </span>-->
    <!--        <span class="flex-none">{{ time }}</span>-->
    <!--      </div>-->
    <!--    </b-card-sub-title>-->

    <tutorial-step
      v-for="(step, i) in stepsArray"
      :key="`step-${i}`"
      :number="i+1"
      :text="step.text"
      :image="step.image"
      :success="step.success"
    />
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import TutorialStep from '@/views/models/apps/TutorialStep.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    TutorialStep,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    steps: {
      type: [Array, Object],
      default: Array,
    },
    time: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isCollapsed: false,
    }
  },
  computed: {
    stepsArray() {
      return Object.values(this.steps)
    },
    stepsCount() {
      return this.stepsArray.filter(step => !step.success).length
    },
    stepsLabel() {
      return (this.stepsCount >= 3 || this.stepsCount <= 10) ? '3_to_10_steps' : 'gt_10_step'
    },
  },
}
</script>

<style>
h6.card-subtitle.text-muted {
  margin-top: 0.5rem;
}
</style>
